<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :title="
      $t('admin.manageAccountFeaturesDialog.title', [account.companyName])
    "
    :width="650"
    inset
    show-actions
    :loading="loading"
  >
    <v-card flat v-for="(item, index) of allFeatures" :key="`${item}_${index}`">
      <v-card-title class="text-body-1 font-weight-semibold">{{
        item.title
      }}</v-card-title>
      <v-card-text>
        <v-switch
          v-for="(cap, index) in item.capabilities"
          :input-value="isFeatureActive(cap)"
          :key="`${cap}_${index}`"
          :label="$t(`admin.accountFeatures.${cap}`)"
          hide-details
          color="primary"
          @change="onFeatureValueChange($event, cap)"
        />
      </v-card-text>
    </v-card>

    <template #actions>
      <v-spacer />
      <cz-button
        :title="$t('common.save')"
        color="primary"
        :min-width="100"
        large
        :disabled="!featuresChanged"
        @click="
          $emit('save', {
            account,
            accountFeatures
          })
        "
        :loading="loading"
      />
    </template>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzButton } from '@/components';
import isEqual from 'lodash.isequal';
export default {
  name: 'ManageAccountFeaturesDialog',
  components: {
    CzDialog,
    CzButton
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    allFeatures() {
      return [
        {
          title: this.$t('admin.maunal_order_creation'),
          capabilities: ['order_creation', 'order_creation_with_items']
        },
        {
          title: this.$t('admin.digital_archive'),
          capabilities: [
            'digital_archive_submissions',
            'digital_archive_orders'
          ]
        }
      ];
    },
    featuresChanged() {
      return !isEqual(this.accountFeatures, this.account?.activeFeatures);
    }
  },
  created() {
    this.accountFeatures = structuredClone(this.account?.activeFeatures);
  },
  data() {
    return {
      accountFeaturesCopy: [],
      accountFeatures: []
    };
  },
  methods: {
    isFeatureActive(feature) {
      return this.accountFeatures.includes(feature);
    },
    onFeatureValueChange(value, feature) {
      if (value) {
        this.accountFeatures.push(feature);
      } else {
        const index = this.accountFeatures.findIndex(
          (item) => item === feature
        );
        if (index !== -1) {
          this.accountFeatures.splice(index, 1);
        }
      }
    },
    initializeAccountFeatures() {
      for (const item of this.allFeatures) {
        this.accountFeatures.push({
          name: item,
          value: this.account?.activeFeatures?.includes(item)
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
